import { ShopifyCollection } from "@/types/shopify.ts";
import ShopifyCollectionsCombobox from "@admin/components/shopify/shopify-collections-combobox.tsx";
import { Input } from "@components/ui/input.tsx";
import { ToggleGroup, ToggleGroupItem } from "@components/ui/toggle-group.tsx";
import { cn } from "@lib/utils.ts";
import { Link, Unlink } from "lucide-react";
import React from "react";

type ShopifyProductLinkTableFiltersProps =
  React.ComponentPropsWithoutRef<"div"> & {
    filters: ShopifyProductFilters;
    onFiltersChange: (filters: ShopifyProductFilters) => void;
    collections: ShopifyCollection[];
    searchInputClassName?: string;
  };

export type ShopifyProductFilters = {
  title?: string;
  collection?: string;
  links: string[];
};

export default function ShopifyProductLinkTableFilters({
  className,
  filters,
  onFiltersChange,
  collections,
  searchInputClassName,
  ...props
}: ShopifyProductLinkTableFiltersProps) {
  return (
    <div className={cn("flex items-center gap-3", className)} {...props}>
      <ShopifyCollectionsCombobox
        collections={collections}
        onSelect={(collection) =>
          onFiltersChange({ ...filters, collection: collection?.id })
        }
      />

      <Input
        className={cn("max-w-sm bg-gray-50", searchInputClassName)}
        placeholder="Filtrer les produits..."
        value={filters.title}
        onChange={(event) =>
          onFiltersChange({ ...filters, title: event.target.value })
        }
      />

      {/* toggle link/unlink products */}
      <ToggleGroup
        value={filters.links}
        onValueChange={(links) => onFiltersChange({ ...filters, links })}
        className="ml-auto"
        type="multiple"
        size="sm"
      >
        <ToggleGroupItem
          className={cn(
            "data-[state=off]:!bg-transparent data-[state=on]:!bg-white",
            "!border data-[state=off]:!border-white/75",
            "data-[state=off]:hover:!bg-white/50",
          )}
          value="link"
          aria-label="Afficher les produits avec un modèle associé"
        >
          <Link className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem
          className={cn(
            "data-[state=off]:!bg-transparent data-[state=on]:!bg-white",
            "!border data-[state=off]:!border-white/75",
            "data-[state=off]:hover:!bg-white/50",
          )}
          value="unlink"
          aria-label="Afficher les produits sans association"
        >
          <Unlink className="h-4 w-4" />
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  );
}
