import { ModelConfig } from "@/types/model.ts";
import { Button } from "@components/ui/button.tsx";
import { Checkbox } from "@components/ui/checkbox.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { cn } from "@lib/utils.ts";
import { PopoverProps } from "@radix-ui/react-popover";
import { Settings } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

type ModelConfigPopoverProps = PopoverProps & {
  triggerClassName?: string;
};

export default function ModelConfigPopover({
  triggerClassName,
  ...props
}: ModelConfigPopoverProps) {
  const [open, setOpen] = useState<boolean>(false);

  const {
    state: {
      customModel: {
        model: { modelConfig },
      },
    },
    dispatch,
  } = useCustomModelEditorContext();

  const [value, setValue] = useState<ModelConfig>(modelConfig);

  function handleValidate() {
    dispatch({ type: "update_model_config", payload: value });
    toast.success("Configuration du modèle mise à jour");
    setOpen(false);
  }

  return (
    <Popover open={open} onOpenChange={setOpen} {...props}>
      <PopoverTrigger className={cn("hover:opacity-75", triggerClassName)}>
        <Settings className="h-[25px] w-[25px]" />
      </PopoverTrigger>

      <PopoverContent side="bottom" align="end" sideOffset={20}>
        <div className="flex flex-col gap-2">
          <div className="text-lg font-semibold">Configuration du modèle</div>

          <div className="flex flex-col gap-2">
            {/* Logos */}
            <label className="flex cursor-pointer items-center gap-1.5 underline-offset-2 hover:underline">
              <Checkbox
                checked={value.logos}
                onCheckedChange={(checked) =>
                  setValue((prevState) => ({ ...prevState, logos: !!checked }))
                }
              />
              Logos
            </label>

            {/* Custom Numbers */}
            <label className="flex cursor-pointer items-center gap-1.5 underline-offset-2 hover:underline">
              <Checkbox
                checked={value.customNumbers}
                onCheckedChange={(checked) =>
                  setValue((prevState) => ({
                    ...prevState,
                    customNumbers: !!checked,
                  }))
                }
              />
              Numéros
            </label>

            {/* Custom Texts */}
            <label className="flex cursor-pointer items-center gap-1.5 underline-offset-2 hover:underline">
              <Checkbox
                checked={value.customTexts}
                onCheckedChange={(checked) =>
                  setValue((prevState) => ({
                    ...prevState,
                    customTexts: !!checked,
                  }))
                }
              />
              Textes
            </label>
          </div>

          <Button
            className="self-end"
            variant="primary"
            size="sm"
            onClick={handleValidate}
          >
            Valider
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
