import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { cn } from "@lib/utils.ts";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import { ChevronDown } from "lucide-react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

type LocaleSwitcherProps = DropdownMenuProps & {
  triggerClassName?: string;
};

const lngs = {
  fr: {
    flag: "FR",
    nativeName: "Français",
  },
  en: {
    flag: "US",
    nativeName: "English",
  },
};

export default function LocaleSwitcher({
  triggerClassName,
  ...props
}: LocaleSwitcherProps) {
  const {
    i18n: { resolvedLanguage, changeLanguage },
  } = useTranslation();

  return (
    <DropdownMenu {...props}>
      <DropdownMenuTrigger
        className={cn("group flex items-center", triggerClassName)}
      >
        <ReactCountryFlag
          className="mr-2 text-2xl"
          countryCode={lngs[resolvedLanguage as keyof typeof lngs].flag}
          svg
        />
        <span>{lngs[resolvedLanguage as keyof typeof lngs].nativeName}</span>
        <ChevronDown
          size={20}
          className="ml-1 duration-200 group-radix-state-open:rotate-180"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" sideOffset={16} side="top">
        <DropdownMenuGroup>
          {Object.keys(lngs).map((lng) => (
            <DropdownMenuItem
              key={lng}
              onClick={() => changeLanguage(lng)}
              className={cn("flex items-center gap-1.5", {
                "font-bold": lng === resolvedLanguage,
              })}
            >
              <span>{lngs[lng as keyof typeof lngs].flag}</span>
              <span>{lngs[lng as keyof typeof lngs].nativeName}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
