import {
  CUSTOM_LAB_COLORS,
  CUSTOM_NUMBERS_DEFAULT_COLOR,
  CUSTOM_NUMBERS_DEFAULT_FONT,
  CUSTOM_NUMBERS_DEFAULT_FONT_SIZE,
  CUSTOM_NUMBERS_FONTS,
  CUSTOM_NUMBERS_STROKE_DEFAULT_COLOR,
} from "@/config.ts";
import { CustomNumbers, CustomText } from "@/types/custom-element.ts";
import { Button } from "@components/ui/button.tsx";
import TextEditor, {
  TextEditorValue,
} from "@custom-model-editor/components/text-editor/text-editor.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { getCustomElementsColorMode } from "@custom-model-editor/lib/custom-model.ts";
import {
  addClassToElement,
  makeElementClickable,
  makeElementInteractive,
  makeElementNonInteractive,
  removeClassFromElement,
} from "@custom-model-editor/lib/svg.ts";
import { cn } from "@lib/utils.ts";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type StepsMenuCustomNumbersProps = React.ComponentPropsWithoutRef<"div">;

export default function StepsMenuCustomNumbers({
  className,
  ...props
}: StepsMenuCustomNumbersProps) {
  const [customNumbers, setCustomNumbers] =
    useState<Omit<CustomNumbers, "fontSize">>(initCustomNumbers());

  const { t } = useTranslation("steps-menu-custom-numbers");

  function initCustomNumbers(): Omit<CustomText, "fontSize"> {
    return {
      text: "",
      font: CUSTOM_NUMBERS_DEFAULT_FONT,
      color: CUSTOM_NUMBERS_DEFAULT_COLOR,
      stroke: {
        color: CUSTOM_NUMBERS_STROKE_DEFAULT_COLOR,
        width: 0,
      },
      spacing: 0,
    };
  }

  const {
    state: { elementSelected, customModel },
    dispatch,
  } = useCustomModelEditorContext();

  const colorMode = useMemo(
    () => getCustomElementsColorMode(customModel),
    [customModel],
  );

  /**
   * Make the custom numbers elements interactive when the component mount.
   * Remove the interactivity when the component unmount.
   */
  useEffect(() => {
    const customNumbersElements = customModel.elements.filter(
      (el) => el.type === "custom-numbers",
    );

    customNumbersElements.forEach((el) => {
      addClassToElement(customModel.id, el.id, "interactive");
      makeElementInteractive(customModel.id, el.id);
    });
    makeElementClickable(customModel.id, "custom-numbers", true);

    return () => {
      customNumbersElements.forEach((el) => {
        removeClassFromElement(customModel.id, el.id, "interactive");
        makeElementNonInteractive(customModel.id, el.id);
      });
      makeElementClickable(customModel.id, "custom-numbers", false);
    };
  }, [customModel.id, customModel.elements]);

  /**
   * Update the custom text when the element selected changes.
   */
  useEffect(() => {
    if (!elementSelected) {
      setCustomNumbers(initCustomNumbers());
      return;
    }
    if (elementSelected?.type !== "custom-numbers") return;
    setCustomNumbers(elementSelected.customNumbers);
  }, [elementSelected]);

  function handleAddCustomNumbers() {
    dispatch({
      type: "add_custom_numbers",
      payload: { ...customNumbers, fontSize: CUSTOM_NUMBERS_DEFAULT_FONT_SIZE },
    });
  }

  function handleChange(change: TextEditorValue) {
    setCustomNumbers({
      ...change,
      text: change.text.replace(/[^0-9]/g, ""),
    });

    if (elementSelected) {
      dispatch({
        type: "update_custom_numbers",
        payload: {
          customNumbersId: elementSelected.id,
          updates: { ...change },
        },
      });
    }
  }

  return (
    <div
      className={cn(
        "fancy-scrollbar overflow-y-auto px-1 pb-1 xl:px-2",
        className,
      )}
      style={{ height: !elementSelected ? "calc(100% - 3.5rem)" : "100%" }}
      {...props}
    >
      <TextEditor
        className="relative"
        title={elementSelected ? t("update-title") : t("add-title")}
        placeholder={t("text-editor-placeholder")}
        value={customNumbers}
        onChange={handleChange}
        settings={{
          fontList: CUSTOM_NUMBERS_FONTS,
          textColorList: CUSTOM_LAB_COLORS,
          strokeColorList: CUSTOM_LAB_COLORS,
          colorMode,
        }}
      />

      {!elementSelected ? (
        <div className={cn("absolute bottom-0 left-0 right-0 p-1.5 lg:p-3")}>
          <Button
            className="h-9 w-full font-semibold uppercase lg:h-10"
            variant="primary"
            onClick={handleAddCustomNumbers}
          >
            {t("add-btn")}
          </Button>
        </div>
      ) : null}
    </div>
  );
}
