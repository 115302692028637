import Sidebar from "@components/layout/sidebar.tsx";
import AskLandscape from "@custom-model-editor/components/ask-landscape.tsx";
import CardError from "@custom-model-editor/components/card-error.tsx";
import { Trans, useTranslation } from "react-i18next";

export default function CustomizationErrorRoute() {
  const { t } = useTranslation("customization-error-route");

  return (
    <AskLandscape>
      <Sidebar>
        <div className="p-2">{t("title")}</div>
      </Sidebar>
      <div className="grid place-items-center">
        <CardError>
          <Trans ns="customization-error-route" i18nKey="description">
            Une erreur inattendue s'est produite.
            <br />
            Veuillez réessayez plus tard ou essayez de rafraichir la page.
          </Trans>
        </CardError>
      </div>
    </AskLandscape>
  );
}
