import { Color, ColorMode, Pattern } from "@/types/color-elements.ts";
import { cn, whiteOrBlack } from "@lib/utils.ts";
import React, { useMemo } from "react";
import { FaCheck } from "react-icons/fa6";

type ColorSquareProps = Omit<React.ComponentPropsWithoutRef<"div">, "color"> & {
  color: Color;
  colorMode?: ColorMode;
  pattern?: Pattern;
  size?: "sm" | "md" | "lg" | "xl";
  checked?: boolean;
};

export default function ColorSquare({
  className,
  color: colorProp,
  colorMode = "flat",
  pattern,
  size = "md",
  checked = false,
  ...props
}: ColorSquareProps) {
  const sizeVariants = {
    sm: "w-6 h-6",
    md: "w-8 h-8",
    lg: "w-9 h-9",
    xl: "w-11 h-11",
  };

  const backgroundStyle = useMemo(() => {
    const image = pattern ? `url(${pattern.url})` : undefined;

    const backgroundColor =
      colorProp.name === "NO_NAME" ? "#fff" : colorProp.color;

    const gradient =
      colorMode === "chromium" ||
      (colorMode === "chromium-white" && colorProp.name !== "WHITE");

    const backgroundImage =
      gradient && colorProp.gradient && colorProp.name !== "NO_NAME"
        ? `${image ? `${image}, ` : ""}linear-gradient(90deg, ${colorProp.gradient.map((g) => `${g.color} ${g.location}%`).join(", ")})`
        : pattern
          ? image
          : undefined;

    const backgroundSize = pattern
      ? `${pattern.thumbnailSize * 2}%`
      : undefined;

    return { backgroundColor, backgroundImage, backgroundSize };
  }, [colorProp, colorMode, pattern]);

  return (
    <>
      <div
        className={cn(
          sizeVariants[size],
          "relative",
          "rounded-sm transition-transform duration-200",
          "shadow-[0_0_7px_0px_#4f4f4f]",
          { "grid place-items-center": checked },
          { "outline outline-offset-2 outline-white": checked && pattern },
          { "cursor-pointer hover:scale-110": props.onClick },
          className,
        )}
        style={{ ...backgroundStyle }}
        {...props}
      >
        {/* if unknown color, draw a red line crossing the circle diagonally */}
        {colorProp?.name === "NO_NAME" ? (
          <div
            className="h-full w-full rounded-full bg-red-500"
            style={{ clipPath: "polygon(90% 0%, 110% 0%, 10% 100%, 0% 90%)" }}
          ></div>
        ) : null}

        {/* draw a check icon in the center (with pattern, use outline instead) */}
        {checked && !pattern ? (
          <FaCheck
            className="absolute h-4 w-4"
            style={{
              fill: whiteOrBlack(colorProp.color),
            }}
          />
        ) : null}
      </div>
    </>
  );
}
