import { ShopifyProduct, ShopifyProductLink } from "@/types/shopify.ts";
import { ShopifyProductFilters } from "@admin/components/shopify/shopify-product-link-table-filters.tsx";
import ShopifyProductStatusBadge from "@admin/components/shopify/shopify-product-status-badge.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@components/ui/table.tsx";
import { cn, getModelTitle } from "@lib/utils.ts";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { TriangleAlert } from "lucide-react";
import { useEffect, useMemo, useState } from "react";

type ShopifyProductLinkTableProps = {
  products: ShopifyProduct[];
  filters: ShopifyProductFilters;
  onRowClick?: (row: ShopifyProductLink) => void;
  tableClassName?: string;
  headerClassName?: string;
};

export default function ShopifyProductLinkTable({
  products,
  filters,
  onRowClick,
  tableClassName,
  headerClassName,
}: ShopifyProductLinkTableProps) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const {
    state: { models },
  } = useAdminContext();

  const data: ShopifyProductLink[] = useMemo(
    () =>
      products
        .map((product) => {
          const metafield = product.metafields.nodes.find(
            (mf) => mf.key === "custom_lab_model_id",
          );
          const model = models.find((m) => m.id === metafield?.value);

          return {
            product,
            model,
          };
        })
        .sort((a, b) => (a.product.title < b.product.title ? -1 : 1)),
    [products, models],
  );

  useEffect(() => {
    table.getColumn("shopify-product")?.setFilterValue(() => ({
      ...filters,
    }));
  }, [filters]);

  const columns: ColumnDef<ShopifyProductLink>[] = [
    {
      id: "shopify-product",
      accessorFn: (row) => row.product,
      filterFn: (row, columnId, filterValue: ShopifyProductFilters) => {
        const rowValue = row.getValue<ShopifyProduct>(columnId);

        // filter by link status
        const mf = rowValue.metafields.nodes.find(
          (mf) => mf.key === "custom_lab_model_id",
        );
        if (
          !filterValue.links.includes(
            mf && mf.value !== "none" ? "link" : "unlink",
          )
        )
          return false;

        // filter by collection (if selected)
        if (
          filterValue.collection &&
          !rowValue.collections.nodes.some(
            (c) => c.id === filterValue.collection,
          )
        )
          return false;

        // filter by title
        return rowValue.title
          .toLowerCase()
          .trim()
          .includes(filterValue.title?.toLowerCase().trim() ?? "");
      },
      cell: (row) => (
        <>
          <div className="flex items-center gap-2">
            {row.getValue<ShopifyProduct>().featuredImage ? (
              <img
                className="aspect-square h-12 w-auto rounded-lg border object-contain"
                src={row.getValue<ShopifyProduct>().featuredImage.url}
                alt={`Image du produit ${row.getValue<ShopifyProduct>().title}`}
              />
            ) : (
              <div className="grid h-12 w-12 place-items-center rounded-lg border">
                ?
              </div>
            )}

            <span className="group-hover:underline">
              {row.getValue<ShopifyProduct>().title}
            </span>
          </div>
        </>
      ),
      header: "Produit shopify",
    },
    {
      id: "shopify-product-status",
      accessorFn: (row) => row.product,
      cell: (row) => (
        <span className="self-end text-xs uppercase">
          <ShopifyProductStatusBadge
            status={row.getValue<ShopifyProduct>().status}
          />
        </span>
      ),
      header: "Produit shopify status",
    },
    {
      id: "custom-lab-model",
      accessorFn: (row) => row,
      cell: (row) => {
        const productLink = row.getValue<ShopifyProductLink>();
        const mf = productLink.product.metafields.nodes.find(
          (mf) => mf.key === "custom_lab_model_id",
        );

        // if the product is associated with a deleted model
        if (mf && mf.value !== "none" && !productLink.model) {
          return (
            <span className="flex items-center gap-1.5 text-error">
              <TriangleAlert className="h-4 w-4 -translate-y-[1px]" /> Modèle
              supprimé ou corrompu
            </span>
          );
        }

        return (
          <span className="cursor-pointer group-hover:underline">
            {productLink.model?.name ? getModelTitle(productLink.model) : "/"}
          </span>
        );
      },
      header: "Modèle Custom Lab",
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
    },
  });

  return (
    <>
      <Table className={cn(tableClassName)}>
        <TableCaption>
          Les associations Produits Shopify - Modèles CUSTOM LAB
        </TableCaption>

        <TableHeader className={cn(headerClassName)}>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className="text-slate-700">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className="group relative cursor-pointer odd:bg-gray-50 hover:bg-gray-100"
                data-state={row.getIsSelected() && "selected"}
                onClick={() => onRowClick?.(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="relative first:p-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell className="text-center" colSpan={columns.length}>
                Aucun résultat
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}
