import { cn } from "@/lib/utils";
import { Input, InputProps } from "@components/ui/input.tsx";
import { Search } from "lucide-react";

type SearchInputProps = InputProps & {
  wrapperClassName?: string;
  iconClassName?: string;
};

export default function SearchInput({
  className,
  wrapperClassName,
  iconClassName,
  ...props
}: SearchInputProps) {
  return (
    <div className={cn("relative h-9", wrapperClassName)}>
      <Search
        className={cn(
          "absolute left-2 top-1/2 -translate-y-1/2",
          "h-4 w-4 stroke-slate-500",
          iconClassName,
        )}
      />
      <Input className={cn("pl-[2.25em]", className)} {...props} />
    </div>
  );
}
