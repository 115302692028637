import { Model } from "@/types/model.ts";
import { ShopifyCollection, ShopifyProductLink } from "@/types/shopify.ts";
import ShopifyProductLinkTableFilters, {
  ShopifyProductFilters,
} from "@admin/components/shopify/shopify-product-link-table-filters.tsx";
import ShopifyProductLinkTable from "@admin/components/shopify/shopify-product-link-table.tsx";
import ConfirmUnlinkProductAlertDialog from "@admin/components/shopify/shopify-product-link/confirm-unlink-product-alert-dialog.tsx";
import ShopifyProductLinkDialog from "@admin/components/shopify/shopify-product-link/shopify-product-link-dialog.tsx";
import { useAdminContext } from "@admin/hooks/use-admin-context.ts";
import MxlabLoader from "@components/ui/mxlab-loader.tsx";
import { api } from "@lib/api.ts";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";

export default function AdminShopifyRoute() {
  const [loading, setLoading] = useState<boolean>(false);

  const [productLinkSelected, setProductLinkSelected] =
    useState<ShopifyProductLink>();
  const [showConfirmUnlinkDialog, setShowConfirmUnlinkDialog] =
    useState<boolean>(false);

  const [filters, setFilters] = useState<ShopifyProductFilters>({
    title: "",
    collection: "",
    links: ["link", "unlink"],
  });

  const {
    dispatch,
    state: { shopifyProducts: products },
  } = useAdminContext();

  const collections: ShopifyCollection[] = useMemo(() => {
    const notFiltered = products.flatMap((p) => p.collections.nodes);
    const ids = notFiltered.map((c) => c.id);
    return notFiltered.filter((c, index) => !ids.includes(c.id, index + 1));
  }, [products]);

  useEffect(() => {
    setLoading(true);
    api
      .getShopifyProducts()
      .then((products) => {
        dispatch({ type: "set_shopify_products", payload: products });
      })
      .finally(() => setLoading(false));
  }, []);

  function handleRowClick(row: ShopifyProductLink) {
    setProductLinkSelected(row);
  }

  async function handleLink(
    productLink: ShopifyProductLink,
    modelToLink: Model,
  ) {
    try {
      const promise = api.linkShopifyProductToCustomLabModel(
        productLink.product,
        modelToLink.id,
      );

      toast.promise(promise, {
        loading: "Association en cours...",
        duration: 7000,
        success: "Association réussie",
        error:
          "Erreur lors de l'association. Veuillez réessayer plus tard ou contactez le développeur.",
      });

      const { product: updatedProduct, model: updatedModel } = await promise;
      setProductLinkSelected({ product: updatedProduct, model: modelToLink });
      dispatch({ type: "update_shopify_product", payload: updatedProduct });
      dispatch({ type: "update_model", payload: updatedModel });
    } catch (err) {
      console.log("Error while linking product");
      console.error(err);
    }
  }

  async function handleUnlink(productLink: ShopifyProductLink) {
    const { product } = productLink;

    try {
      const promise = api.linkShopifyProductToCustomLabModel(product);

      toast.promise(promise, {
        loading: "Dé-association en cours...",
        duration: 7000,
        success: "Les produits ont été dé-associés",
        error:
          "Erreur lors de la dé-association. Veuillez réessayer plus tard ou contactez le développeur.",
      });

      const { product: updatedProduct, model: updatedModel } = await promise;
      setProductLinkSelected({ product: updatedProduct, model: undefined });
      dispatch({ type: "update_shopify_product", payload: updatedProduct });
      dispatch({ type: "update_model", payload: updatedModel });
    } catch (err) {
      console.log("Error while unlinking product");
      console.error(err);
    }
  }

  return (
    <>
      {loading ? (
        <div className="grid h-full place-items-center">
          <MxlabLoader
            color="black"
            text="Chargement des produits shopify..."
          />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between">
            <div className="text-xl font-black uppercase text-black">
              Shopify
            </div>
            <Link
              className="text-sm text-slate-950 underline underline-offset-2 after:content-['_↗'] hover:opacity-75"
              to={import.meta.env.VITE_SHOPIFY_STORE_ADMIN_URL}
              target="_blank"
            >
              Ouvrir Shopify
            </Link>
          </div>
          <div className="mb-3 text-sm text-slate-950">
            Associe les produits Shopify avec les modèles CustomLab pour qu'ils
            soient configurables sur le site.
          </div>

          <div className="">
            <ShopifyProductLinkTableFilters
              filters={filters}
              onFiltersChange={setFilters}
              collections={collections}
            />

            <div
              className="mt-3 overflow-auto rounded-lg bg-white pb-4"
              style={{ height: `calc(100dvh - 177px)` }}
            >
              <ShopifyProductLinkTable
                tableClassName="text-slate-950"
                products={products}
                filters={filters}
                onRowClick={handleRowClick}
              />
            </div>
          </div>

          {productLinkSelected ? (
            <ShopifyProductLinkDialog
              open
              onOpenChange={(change) =>
                !change && setProductLinkSelected(undefined)
              }
              productLink={productLinkSelected}
              onLink={handleLink}
              onUnlink={() => setShowConfirmUnlinkDialog(true)}
            />
          ) : null}

          {productLinkSelected && showConfirmUnlinkDialog ? (
            <ConfirmUnlinkProductAlertDialog
              open={showConfirmUnlinkDialog}
              onOpenChange={setShowConfirmUnlinkDialog}
              productLink={productLinkSelected}
              onConfirm={() => handleUnlink(productLinkSelected)}
            />
          ) : null}
        </>
      )}
    </>
  );
}
