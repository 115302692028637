import { ShopifyCollection } from "@/types/shopify.ts";
import { Button } from "@components/ui/button.tsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@components/ui/command.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover.tsx";
import { cn } from "@lib/utils.ts";
import { Check, ChevronsUpDown } from "lucide-react";
import { useState } from "react";

type ShopifyCollectionsComboboxProps = {
  collections: ShopifyCollection[];
  onSelect: (collection?: ShopifyCollection) => void;
  triggerClassName?: string;
};

export default function ShopifyCollectionsCombobox({
  collections,
  onSelect,
  triggerClassName,
}: ShopifyCollectionsComboboxProps) {
  const [value, setValue] = useState<string>();
  const [open, setOpen] = useState(false);

  function handleValueChange(change: string) {
    if (value === change) {
      onSelect(undefined);
      setValue(undefined);
    } else {
      onSelect(collections.find((c) => c.title === change));
      setValue(change);
    }
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            "min-w-[150px] justify-between text-sm normal-case text-slate-600",
            triggerClassName,
          )}
          variant="outline"
          size="sm"
          role="combobox"
          aria-expanded={open}
        >
          {value || "Collection Shopify..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="p-0">
        <Command>
          <CommandInput placeholder="Recherche une collection" />
          <CommandEmpty>Aucune collection trouvée</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {collections.map((collection) => {
                return (
                  <CommandItem
                    key={collection.id}
                    value={collection.title}
                    onSelect={(currentValue) => {
                      handleValueChange(currentValue);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === collection.title
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {collection.title}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
