import { Button } from "@components/ui/button.tsx";
import LocaleSwitcher from "@custom-model-editor/components/locale-switcher.tsx";
import StepsMenuNotice from "@custom-model-editor/components/steps-menus/steps-menu-notice.tsx";
import { useCustomModelEditorContext } from "@custom-model-editor/hooks/use-custom-model-editor-context.ts";
import { cn } from "@lib/utils.ts";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

type StepsMenuProps = React.ComponentPropsWithoutRef<"div">;

export default function StepsMenu({ className, ...props }: StepsMenuProps) {
  const {
    state: { stepBarItems, currentStepBarItem },
    dispatch,
  } = useCustomModelEditorContext();

  const { t } = useTranslation("steps-menu");

  /**
   * Retrieve the current step menu component
   */
  const MenuComponent = useMemo(
    () =>
      currentStepBarItem ? (
        <currentStepBarItem.component {...currentStepBarItem.componentProps} />
      ) : (
        <StepsMenuNotice />
      ),
    [currentStepBarItem],
  );

  return (
    <div
      className={cn("h-full", "flex flex-col justify-between gap-4", className)}
      {...props}
    >
      <div
        className={cn("h-full overflow-hidden", {
          "mb-14": !currentStepBarItem,
        })}
      >
        {MenuComponent}

        {!currentStepBarItem ? (
          <div className="absolute bottom-0 left-0 right-0 bg-[#323232] p-1.5 lg:p-3">
            <div className="mb-2 lg:mb-3">
              <LocaleSwitcher />
            </div>
            <Button
              className="h-9 w-full font-semibold uppercase lg:h-10"
              variant="primary"
              onClick={() =>
                dispatch({
                  type: "set_current_step_bar_item",
                  payload: stepBarItems[0],
                })
              }
            >
              {t("start-btn")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
